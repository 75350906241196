import { useEffect, useState } from "react";

const useCountdown = ({ targetSeconds, expireTime, onFinish = () => {} }: { targetSeconds: number; expireTime: string; onFinish: () => void }) => {
  const [countDown, setCountDown] = useState(targetSeconds);

  useEffect(() => {
    // console.log(`SECONDS: left ${countDown} | passed ${targetSeconds - countDown}`);
    if (countDown > 0) {
      let expireDate = new Date(expireTime);
      window.setTimeout(() => {
        let currentDate = new Date();
        let difference = expireDate.getTime() - currentDate.getTime();
        let differenceSeconds = Math.floor(difference/1000);
        setCountDown(differenceSeconds);
      }, 1000);
    } else {
      onFinish();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countDown]);

  return getReturnValues(countDown * 1000);
};


const getReturnValues = (countDown: number) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

export { useCountdown };
